/* assistant-300 - latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/assistant-v4-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Assistant Light'), local('Assistant-Light'),
    url('../fonts/assistant-v4-latin-300.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/assistant-v4-latin-300.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/assistant-v4-latin-300.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/assistant-v4-latin-300.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/assistant-v4-latin-300.svg#Assistant') format('svg'); /* Legacy iOS */
}

/* assistant-regular - latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/assistant-v4-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Assistant'), local('Assistant-Regular'),
    url('../fonts/assistant-v4-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/assistant-v4-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/assistant-v4-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/assistant-v4-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/assistant-v4-latin-regular.svg#Assistant')
      format('svg'); /* Legacy iOS */
}

/* assistant-600 - latin */
@font-face {
  font-family: 'Assistant';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/assistant-v4-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Assistant SemiBold'), local('Assistant-SemiBold'),
    url('../fonts/assistant-v4-latin-600.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../fonts/assistant-v4-latin-600.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/assistant-v4-latin-600.woff') format('woff'),
    /* Modern Browsers */ url('../fonts/assistant-v4-latin-600.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/assistant-v4-latin-600.svg#Assistant') format('svg'); /* Legacy iOS */
}
