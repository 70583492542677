/* LOADER */
.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 16px;
}
.lds-ellipsis div {
  position: absolute;
  top: 1px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.profile-menu {
  border-radius: 4px;
  transition: background-color 0.2s;
  font-size: 14px !important;
}

.anchor-brand-secondary:hover {
  color: #1d9800;
}

.one-line-paragraph {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
}

.institution-programs {
  min-width: 295px !important;
}
.suggested-courses {
  min-width: 260px !important;
  z-index: 3;
}

#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar {
  width: 1px !important;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: #ddd;
}

.playcardwrapper:focus {
  background-color: #6ca4d4dc;
  color: #fff;
}
.playcardwrapper:hover {
  background-color: #6ca4d4dc;
  color: #fff;
}
.playcardinactive:focus {
  background-color: #dadada;
}
.playcardinactive:hover {
  background-color: #dadada;
}
.playcardwrapper {
  color: #000;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

/* pagination css */

.pageitem:first-child .pagelink-item {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.pagelink-item:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
a:focus,
a:hover {
  text-decoration: none;
}
a:focus,
input:focus,
textarea:focus,
button:focus {
  text-decoration: none;
  outline: none;
}
.pagelink-item {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.pageitem:last-child .pagelink-item {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-wrapper {
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.pagelink-item:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
/*  */
.course-card:hover {
  transform: scale(1.02);
}
.course-card {
  transition: 0.2s ease-in-out;
}
